'use client'
import { useGetAdsQuery } from '@/app/globalRedux/api/adsSlice'
import { RootState } from '@/app/globalRedux/store'
import {
  handleAdIntersection,
  useIntersectionObserver,
} from '@/app/hooks/useIntersectionObserver'
import { AdBannerScheduleType } from '@/app/types/adType'
import { getAdsIndex, handleAdClick } from '@/app/utils/AdsUtility'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PositionOneCarousel from './PositionOneCarousel'
import PositionTwoCarousel from './PositionTwoCarousel'

const PanelOne = () => {
  const params = useParams<{ lng: string }>()

  const { data: adsHomePage } = useGetAdsQuery<{
    data: AdBannerScheduleType[]
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }>({ page: 'Home Page', lang: params.lng })

  return (
    <div>
      <div className="flex gap-[26px] mobile:flex-col mobile:gap-[10px]">
        {/* Ad Position 1 */}
        <PositionOneCarousel adList={adsHomePage} />
        {/* Ad Position 2 */}
        <PositionTwoCarousel adList={adsHomePage} />
      </div>
    </div>
  )
}

const PanelTwo = () => {
  const params = useParams<{ lng: string }>()

  const { data: adsHomePage } = useGetAdsQuery<{
    data: AdBannerScheduleType[]
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }>({ page: 'Home Page', lang: params.lng })

  const localeLangauge = useSelector(
    (state: RootState) => state.user.localeLangauge
  )
  const [adForPositionSix, setAdForPositionSix] =
    useState<AdBannerScheduleType>()
  const [adForPositionSeven, setAdForPositionSeven] =
    useState<AdBannerScheduleType>()

  const adPositionSixRef = useRef<HTMLDivElement>(null)
  const adPositionSevenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (adsHomePage) {
      setAdForPositionSix(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 6')]
      )
      setAdForPositionSeven(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 7')]
      )
    }
  }, [adsHomePage])

  const observeElement = useIntersectionObserver(handleAdIntersection)
  useEffect(() => {
    if (adPositionSixRef.current) {
      observeElement(adPositionSixRef.current)
    }
    if (adPositionSevenRef.current) {
      observeElement(adPositionSevenRef.current)
    }
  }, [adPositionSixRef, adPositionSevenRef, observeElement])

  return (
    <div>
      <div className="flex mobile:flex-col mobile:gap-[10px]">
        {/* Ad Position 6 */}
        <div className=" text-center flex relative rounded-[3px] overflow-hidden">
          <div
            className="flex w-[626px] h-[150px] cursor-pointer mobile-width"
            ref={adPositionSixRef}
            data-ad-position="Ad Position 6"
            data-ad-id={adForPositionSix?.ad_banner?.id}
            data-ad-sid={adForPositionSix?.id}
            data-ad-page={adForPositionSix?.page?.name}
            data-ad-company-id={adForPositionSix?.ad_banner?.company?.id}
            data-ad-lang={params?.lng}
          >
            {adForPositionSix ? (
              <Image
                loading="lazy"
                width={626}
                height={150}
                src={adForPositionSix?.ad_banner?.image}
                alt=""
                className=" object-fill w-full h-full"
                onClick={() => handleAdClick(adForPositionSix, params?.lng)}
              />
            ) : (
              <Image
                priority
                width={626}
                height={150}
                className="object-fill "
                src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad6_626x150.png`}
                alt=""
              />
            )}
          </div>
        </div>
        {/* Ad Position 7 */}
        <div className="flex relative ml-[26px] mobile:ml-[0px] rounded-[3px] overflow-hidden">
          <div
            className="flex w-[300px] h-[150px] cursor-pointer mobile-width mobile:h-[200px] banner-carousel"
            ref={adPositionSevenRef}
            data-ad-position="Ad Position 7"
            data-ad-id={adForPositionSeven?.ad_banner?.id}
            data-ad-sid={adForPositionSeven?.id}
            data-ad-page={adForPositionSeven?.page?.name}
            data-ad-company-id={adForPositionSeven?.ad_banner?.company?.id}
            data-ad-lang={params?.lng}
          >
            {adForPositionSeven ? (
              <Image
                loading="lazy"
                width={300}
                height={150}
                src={adForPositionSeven?.ad_banner?.image}
                alt=""
                className=" object-cover w-full h-full"
                onClick={() => handleAdClick(adForPositionSeven, params?.lng)}
              />
            ) : (
              <Image
                priority
                width={300}
                height={150}
                className="object-cover "
                src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad7_300x150.png`}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const PanelThree = () => {
  const params = useParams<{ lng: string }>()
  const { data: adsHomePage } = useGetAdsQuery<{
    data: AdBannerScheduleType[]
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }>({ page: 'Home Page', lang: params.lng })

  const localeLangauge = useSelector(
    (state: RootState) => state.user.localeLangauge
  )
  const [adForPositionEight, setAdForPositionEight] =
    useState<AdBannerScheduleType>()

  useEffect(() => {
    if (adsHomePage) {
      setAdForPositionEight(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 8')]
      )
    }
  }, [adsHomePage])

  const adPositionEightRef = useRef<HTMLDivElement>(null)

  const observeElement = useIntersectionObserver(handleAdIntersection)
  useEffect(() => {
    if (adPositionEightRef.current) {
      observeElement(adPositionEightRef.current)
    }
  }, [adPositionEightRef, observeElement])
  return (
    <div className="flex relative">
      {/* Ad Position 8 */}
      <div
        className="flex w-[952px] h-[150px] cursor-pointer rounded-[3px] overflow-hidden mobile-width"
        ref={adPositionEightRef}
        data-ad-position="Ad Position 8"
        data-ad-id={adForPositionEight?.ad_banner?.id}
        data-ad-sid={adForPositionEight?.id}
        data-ad-page={adForPositionEight?.page?.name}
        data-ad-company-id={adForPositionEight?.ad_banner?.company?.id}
        data-ad-lang={params?.lng}
      >
        {adForPositionEight ? (
          <Image
            loading="lazy"
            width={952}
            height={150}
            src={adForPositionEight?.ad_banner?.image}
            alt=""
            className=" object-fill w-full h-full"
            onClick={() => handleAdClick(adForPositionEight, params?.lng)}
          />
        ) : (
          <Image
            priority
            width={952}
            height={150}
            className="object-fill "
            src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad8_952x150.png`}
            alt=""
          />
        )}
      </div>
    </div>
  )
}

export { PanelOne, PanelThree, PanelTwo }
