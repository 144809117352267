'use client'
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver'
import { NewsItem } from '@/app/types/newsType'
import { Separator } from '@/components/ui/separator'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { MutableRefObject, useEffect, useRef } from 'react'
import NewsResultCardContent from '../../search/news-tab/(components)/NewsResultCardContent'
import { gtmNewsCardViewTracker } from '../GTMTrackers/GTMNewsTracker'
interface NewsCardProps {
  newsData: NewsItem
  isHomePage?: boolean
}

const NewsOutletCard: React.FC<NewsCardProps> = ({
  newsData,
  isHomePage = false,
}) => {
  const params = useParams<{ lng: string }>()
  const newsHomeCardRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const handleIntersection = () => {
    gtmNewsCardViewTracker(
      newsData?.id,
      newsData?.company_id,
      params?.lng ?? 'en',
      newsData?.language_id
    )
  }

  const observeElement = useIntersectionObserver(handleIntersection)

  useEffect(() => {
    if (newsHomeCardRef.current) {
      observeElement(newsHomeCardRef.current)
    }
  }, [observeElement])
  return (
    <div>
      <div
        ref={newsHomeCardRef}
        className={`${isHomePage ? 'mx-6 my-[14px]' : 'shadow-[0_0_6px_0_rgba(0,0,0,0.2)]'}
          rounded-[5px] relative  flex-row flex gap-7 mobile:gap-[0.75rem] p-[10px] mobile:m-0`}
      >
        <div className=" flex mobile:w-[100px] mobile:h-[100px]">
          <Image
            loading="lazy"
            width={197}
            height={166}
            className={`${isHomePage ? '!w-[100px] !h-[100px]' : '!max-w-[197px] !h-[166px]'} relative bg-[#ededed] rounded-[5px] mobile:!w-[100px] mobile:!h-[100px] ${newsData?.images?.length > 0 ? 'object-cover' : 'object-contain'}`}
            src={
              newsData?.images?.length > 0
                ? newsData?.images[0].url
                : '/assets/Icons/noImage/noImage2.svg'
            }
            alt=""
          />
        </div>
        <NewsResultCardContent newsData={newsData} isHomePage={isHomePage} />
      </div>
      {isHomePage && <Separator />}
    </div>
  )
}

export default NewsOutletCard
