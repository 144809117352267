import { useTranslation } from '@/app/i18n/client'
import { CompanyData } from '@/app/types/companyProfileTypes'
import { companyProfileRedirection } from '@/lib/redirectionUtils'
import Image from 'next/image'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import CustomErrorBoundary from '../../ErrorBoundary'
import PlanLogo from '../PlanLogo'
import {
  gtmFromRedirectedToCompany,
  gtmcompanyCardViewTracker,
} from '@/app/[lng]/components/GTMTrackers/GTMCompanyTracker'
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver'

interface CompanyOutletCardProps {
  companyData: CompanyData
}
const CompanyOutletCard: React.FC<CompanyOutletCardProps> = ({
  companyData,
}) => {
  const params = useParams()
  const { t } = useTranslation()

  const companyCardRef: MutableRefObject<HTMLAnchorElement | null> =
    useRef(null)
  const handleIntersection = () => {
    gtmcompanyCardViewTracker(companyData?.id)
  }

  const observeElement = useIntersectionObserver(handleIntersection)

  useEffect(() => {
    if (companyCardRef.current) {
      observeElement(companyCardRef.current)
    }
  }, [observeElement])

  return (
    <Link
      ref={companyCardRef}
      href={companyProfileRedirection(
        companyData?.industry,
        companyData?.friendly_url,
        String(params?.lng) ?? 'en'
      )}
      onClick={() => {
        gtmFromRedirectedToCompany(
          'top_companies',
          companyData?.friendly_url,
          companyData?.id?.toString()
        )
      }}
    >
      <div className="w-full rounded-[5px] my-6 ps-6 flex cursor-pointer mobile:gap-2">
        <div className="w-[71px] h-[70px] flex justify-center items-center shadow-[0_3px_6px_0_rgba(0,0,0,0.16)]">
          {companyData?.logo ? (
            <Image
              loading="lazy"
              width={56}
              height={56}
              className="object-cover"
              src={companyData?.logo}
              alt=""
            />
          ) : (
            <Image
              loading="lazy"
              width={56}
              height={56}
              className=" w-[56px] h-[56px] object-cover"
              src="/assets/Icons/noImage/noImage2.svg"
              alt=""
            />
          )}
        </div>
        <div className="flex flex-col justify-between mx-5 w-[255px] mobile:mx-0 ">
          {/* Name and Membership */}
          <div className="flex items-center mobile:justify-between mobile:w-full">
            {/* Name */}
            <div className="w-[80%] mobile:w-[80%]">
              <p className="text-[16px] w- text-[#000000] font-ns_bold overflow-hidden  text-ellipsis max-w-xs">
                {companyData?.name}
              </p>
            </div>
            {/* Membership */}
            <div className="flex ms-3 items-baseline justify-between mobile:w-[40%] mobile:justify-end mobile:me-3 mobile:ms-0">
              {companyData?.subscription !== 'Free' ? (
                <>
                  <PlanLogo planType={companyData?.subscription} />
                </>
              ) : (
                <p className="text-[#989797]">{t('Free')}</p>
              )}
            </div>
          </div>
          {/* Country */}
          <div className="flex items-baseline gap-2 text-[12px] text-[#8b8b8b]">
            <p>{companyData?.country?.emoji}</p>
            <p>{companyData?.country?.name ?? t('country not available')}</p>
          </div>
          <div className="flex items-center text-[12px] text-[#6f6f6f] font-ns_bold">
            {companyData?.products?.length > 0 ? (
              companyData?.products.map((product, index) => {
                return (
                  <CustomErrorBoundary key={index}>
                    <p className="me-1" key={index}>
                      {index == companyData?.products.length - 1
                        ? product
                        : product + ', '}
                    </p>
                  </CustomErrorBoundary>
                )
              })
            ) : (
              <p>{t('This company has no products')}.</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default CompanyOutletCard
