import { RootState } from '@/app/globalRedux/store'
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import { useTranslation } from '@/app/i18n/client'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { MutableRefObject, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  GTMASOCardViewTracker,
  GTMSOCardClickTracker,
} from '../GTMTrackers/GTMSalesOfferTracker'
import PlanLogo from '../PlanLogo'

const HomeSalesListCard = ({ cardData }: { cardData: any }) => {
  const { t } = useTranslation()
  const params = useParams<{ lng: string }>()
  const pushWithLocale = useLocalizedRouter()
  const userId = useSelector((state: RootState) => state.user.userId)

  const handleClick = () => {
    GTMSOCardClickTracker({
      sales_offer_card_clicked_id: cardData.id,
      sales_offer_card_lang: params?.lng,
      sales_offer_card_company_id: cardData?.company?.id,
      userId: userId,
    })
    pushWithLocale(`/sales_offers/${cardData.id}`)
  }

  const handleSOIntersection = () => {
    if (cardData.id) {
      GTMASOCardViewTracker({
        sales_offer_card_id: cardData.id,
        sales_offer_card_lang: params?.lng,
        sales_offer_card_company_id: cardData?.company?.id,
        userId: userId,
      })
    }
  }

  const salesOfferHomeCardRef: MutableRefObject<HTMLDivElement | null> =
    useRef(null)
  const observeElement = useIntersectionObserver(handleSOIntersection)
  useEffect(() => {
    if (salesOfferHomeCardRef.current) {
      observeElement(salesOfferHomeCardRef.current)
    }
  }, [observeElement])

  return (
    <div
      ref={salesOfferHomeCardRef}
      className={`cursor-pointer overflow-hidden relative border px-5 py-3 grid grid-cols-4 gap-4 rounded-sm border-lightgreen_border bg-light_green hover:border-green_border mobile:grid-cols-4 mobile:gap-[20px] mobile:px-2`}
      onClick={handleClick}
    >
      <div className="flex gap-2 col-span-1">
        <div className="flex flex-col items-center gap-3 mobile:items-center">
          <div
            className={`col-span-1 relative w-[100px] h-[110px] border rounded-sm mobile:w-[100px] mobile:h-[100px]`}
          >
            <Image
              loading="lazy"
              width={100}
              height={110}
              className="object-cover w-full h-full rounded-sm"
              src={
                cardData?.images?.length > 0
                  ? cardData?.images[0]?.url
                  : '/assets/Icons/noImage/noImage2.svg'
              }
              alt="offer-img"
            />
            <span className="text-xs w-[58px] h-[22px] flex items-center justify-center bg-sickly_green text-white rounded-s-[4px] absolute right-0 bottom-0">
              {t('OFFER')}
            </span>
          </div>
          {cardData?.company?.subscription &&
            cardData?.company?.subscription !== 'Free' && (
              <PlanLogo planType={cardData?.company?.subscription} />
            )}
        </div>
      </div>

      <div className="col-span-3 flex flex-col gap-3 mobile:ml-[20px] mobile:gap-1">
        <div className="flex flex-col gap-1">
          <div className="font-ns_bold flex gap-3">
            <p className="text-base whitespace-nowrap overflow-hidden text-ellipsis font-ns_bold mobile:text-wrap mobile:text-[16px]">
              {cardData?.product?.name ?? ''}
              {cardData?.varieties?.length > 0
                ? ` - ${cardData?.varieties[0]?.name}`
                : ''}
              {cardData?.origin
                ? ` - ${t('Origin')} ${cardData?.origin?.name}`
                : ''}
            </p>
          </div>
          <div className="flex items-center gap-2 divide-x text-xs mobile:text-[10px] mobile:gap-1">
            {cardData?.product_type?.length > 0 && (
              <span className=" border bg-chip_bg border-chip_border rounded-full px-2">
                {t(
                  cardData?.product_type?.length === 2
                    ? cardData?.product_type?.join(' & ')
                    : cardData?.product_type?.[0]
                )}
              </span>
            )}
            <p className="pl-2 text-[#4e4e4e]">
              {cardData?.created_at
                ? cardData?.created_at
                : t('date not available')}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 font-ns_bold text-sm mobile:gap-1 mobile:text-[12px]">
          <p className="font-ns_bold">
            {t('Caliber')}:{' '}
            <span className="font-light">
              {cardData?.size ? `${cardData?.size ?? t('NA')}` : t('NA')}
            </span>
          </p>
          <span className="font-ns_bold flex">
            {t('Quality')}:
            <span className="font-light flex ml-1">
              {cardData?.quality?.length == 0 && t('NA')}
              {cardData?.quality?.length > 0 && (
                <span className=" flex flex-row">
                  {cardData?.quality?.[0]?.name}
                  {cardData?.quality?.length > 1 && (
                    <Tooltip
                      title={String(
                        cardData?.quality
                          ?.slice(1, cardData?.quality?.length)
                          .map((item: any) => item.name)
                      )}
                      arrow
                    >
                      <p className=" cursor-pointer ml-1">{` +${cardData?.quality?.length - 1}`}</p>
                    </Tooltip>
                  )}
                </span>
              )}
            </span>
          </span>
          <p className="font-ns_bold">
            {t('Quantity')}:{' '}
            <span className="font-light">
              {cardData?.quantity > 0 ? cardData?.quantity : t('NA')}{' '}
              {cardData?.quantity_unit ? cardData?.quantity_unit?.name : ''}
            </span>
          </p>
          <p className="font-ns_bold truncate max-w-60">
            {t('Packaging')}:{' '}
            <span className="font-light  ">
              {cardData?.packaging ? cardData?.packaging : t('NA')}
            </span>
          </p>
          <p className="font-ns_bold">
            {t('Price')}:{' '}
            <span className="font-light">
              {cardData?.price_unit?.code}{' '}
              {cardData?.price > 0 ? cardData?.price : t('NA')}{' '}
              {cardData?.price_per?.name
                ? `/ ${cardData?.price_per?.name}`
                : ''}
            </span>
          </p>
        </div>
      </div>
      {cardData?.is_sponsored && (
        <div className="font-ns_semibold absolute -right-[2.3rem] bottom-[1.4rem] bg-sponsor_color -rotate-45 text-xs flex items-center justify-center leading-none gap-[0.1rem] px-8">
          <Image
            src="/assets/Icons/blackStar.png"
            alt=""
            width={17.4}
            height={17.4}
          />
          {t('Sponsored')}
        </div>
      )}
    </div>
  )
}

export default HomeSalesListCard
